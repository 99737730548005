import React, { FunctionComponent } from "react";

import styles from "./index.module.scss";

interface Props {
  children: React.ReactNode;
  double?: boolean;
}
const FlexDiv: FunctionComponent<Props> = ({ children, double = false }) => {
  return (
    <div className={`${double ? styles.container : styles.container2}`}>
      {children}
    </div>
  );
};

export default FlexDiv;
