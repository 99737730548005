import React from "react";

import styles from "./../Footer.module.scss";

interface Props {
  label: string;
  path: string;
  className?: string;
}

const FooterLink: React.FC<Props> = ({ label, path, className }) => {
  return (
    <a href={path} target="_blank" rel="noreferrer">
      <p className={`${styles.footer_link} ${className}`}>{label}</p>
    </a>
  );
};

export default FooterLink;
