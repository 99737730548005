import gql from "../../apollo.setup";

export const SUBSCRIBE_TO_NEWSLETTER = gql`
  mutation subscribeToNewsletter(
    $SubscribeToNewsLetterInput: SubscribeToNewsLetterInput!
  ) {
    subscribeToNewsLetter(
      SubscribeToNewsLetterInput: $SubscribeToNewsLetterInput
    ) {
      id
      email
    }
  }
`;
