import { useMutation } from "@apollo/client";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";

import { SUBSCRIBE_TO_NEWSLETTER } from "../../api/graphql/newsLetter";
import useAuth from "../../hooks/useAuth";
import { RoleEnum } from "../../types/enum.types";
import { SubscribeToNewsLetterInput } from "../../types/newsLetter.type";
import { gtmTrigger } from "../../utils/gtm";
import {
  ARTICLES_PAGE,
  DATA_PRIVACY,
  DEMO_URL,
  ENTREPRISES_PAGE,
  FAQ_PAGE,
  HOME_PAGE,
  INSCRIPTION_PAGE,
  LEGAL_NOTICE,
  LOGIN_PAGE,
  OFFERS_PAGE,
  POURQUOI_SWALA_CANDIDATE,
  POURQUOI_SWALA_PAGE,
  POURQUOI_SWALA_RECRUITER,
} from "../../utils/pages";
import ArrowUp from "../assets/icons/ArrowUp";
import Logo from "../assets/icons/Logo";
import FlexDiv from "./FlexDiv";
import styles from "./Footer.module.scss";
import FooterLink from "./FooterLink";

const SectionTitle = styled.div`
  display: flex;
  font-family: var(--font-text-bold);
  font-weight: bold;
  text-transform: uppercase;
  @media screen and (max-width: 992px) {
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
`;
const Flex = styled.div`
  display: flex;
  gap: 10px;
  @media screen and (max-width: 992px) {
    justify-content: center;
  }
`;

const Footer = () => {
  const [email, setEmail] = useState("");

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const [subscribeToNewsletterTrigger, { data, loading }] = useMutation<
    never,
    SubscribeToNewsLetterInput
  >(SUBSCRIBE_TO_NEWSLETTER);

  const handleSubmit = async () => {
    gtmTrigger({
      event: "newsletter-success",
    });
    await subscribeToNewsletterTrigger({
      variables: { SubscribeToNewsLetterInput: { email } },
    });
  };

  const { user } = useAuth();

  return (
    <div className={styles.parent}>
      <div className={styles.arrowUp}>
        <ArrowUp onClick={scrollToTop} />
      </div>
      <div className={styles.relativeParent}>
        <a href="#" className={styles.logo} style={{ height: "50px" }}>
          <Logo white />
        </a>
      </div>
      <div className={styles.container}>
        <div className={styles.section}>
          <SectionTitle>Navigation</SectionTitle>
          <FlexDiv double>
            <FooterLink label="Accueil" path={HOME_PAGE} />
            <FooterLink label="Offres" path={OFFERS_PAGE} />
            <FooterLink label="Connexion" path={LOGIN_PAGE} />
            <FooterLink label="Entreprises" path={ENTREPRISES_PAGE} />
            <FooterLink label="Inscription" path={INSCRIPTION_PAGE} />
            <FooterLink label="Articles" path={ARTICLES_PAGE} />
          </FlexDiv>
          <FooterLink
            label="Demander une démo"
            path={DEMO_URL}
            className={styles.demo_link}
          />
        </div>
        <div className={styles.section}>
          <SectionTitle>infos pratiques</SectionTitle>
          <FlexDiv>
            <a
              href={
                user === undefined
                  ? POURQUOI_SWALA_PAGE
                  : user.role === RoleEnum.APPLICANT
                  ? POURQUOI_SWALA_CANDIDATE
                  : POURQUOI_SWALA_RECRUITER
              }
              target="_blank"
              className={styles.ps}
              rel="noreferrer"
            >
              Pourquoi Swala ?
            </a>
            <a
              href={FAQ_PAGE}
              target="_blank"
              className={styles.ps}
              rel="noreferrer"
            >
              FAQ - Questions fréquentes
            </a>
            <a
              href={`${DATA_PRIVACY}`}
              target="_blank"
              className={styles.ps}
              rel="noreferrer"
            >
              Confidentialité des données
            </a>
            <a
              href={`${LEGAL_NOTICE}`}
              target="_blank"
              className={styles.ps}
              rel="noreferrer"
            >
              Mentions légales
            </a>
            <a
              className={styles.ps}
              href="javascript:Didomi.preferences.show()"
            >
              Paramètres des cookies
            </a>
          </FlexDiv>
        </div>
        <div className={styles.section}>
          <SectionTitle>contact</SectionTitle>
          <FlexDiv>
            <a href="mailto:contact@swala.fr" className={styles.email}>
              contact@swala.fr
            </a>
            <a
              href="https://www.linkedin.com/company/swala-fr/?viewAsMember=true"
              data-trk-social="LinkedIn"
              target="_blank"
              rel="noreferrer"
            >
              <Flex>
                <LazyLoadImage
                  effect="blur"
                  src="/assets/icons/linked-outline.svg"
                  alt="linked-in"
                  height="20px"
                  width="20px"
                />
                <p className={styles.ps}>LinkedIn</p>
              </Flex>
            </a>
            <a
              href={"https://www.instagram.com/swala.fr/?hl=fr"}
              data-trk-social="Instagram"
              target="_blank"
              rel="noreferrer"
            >
              <Flex>
                <LazyLoadImage
                  src="/assets/icons/IG-outline.svg"
                  alt="instagram"
                  height="20px"
                  width="20px"
                />
                <p className={styles.ps}>Instagram</p>
              </Flex>
            </a>

            <a
              href={"https://www.facebook.com/profile.php?id=100090515255899"}
              data-trk-social="Facebook"
              target="_blank"
              rel="noreferrer"
            >
              <Flex>
                <LazyLoadImage
                  src="/assets/icons/facebook-30.svg"
                  alt="facebook"
                  height="20px"
                  width="20px"
                />
                <p className={styles.ps}>Facebook</p>
              </Flex>
            </a>
          </FlexDiv>
        </div>
        <div className={styles.section}>
          <SectionTitle>NEWSLETTER</SectionTitle>
          <FlexDiv>
            <div className={styles.emailSending}>
              <p>Les actus santé en exclusivité !</p>
              <Flex className={styles.emailSending}>
                <label htmlFor="email" hidden>
                  email
                </label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Entrez votre mail"
                />
                <button
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {loading ? "En cours..." : "OK"}
                </button>
              </Flex>
              {data && (
                <p className={styles.success_message}>
                  Votre inscription a été validée.
                </p>
              )}
            </div>
          </FlexDiv>
        </div>
      </div>
    </div>
  );
};

export default Footer;
